@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url('./Montserrat-Regular.ttf') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url('./Montserrat-Italic.ttf') format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url('./Montserrat-Medium.ttf') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'),
        url('./Montserrat-MediumItalic.ttf') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url('./Montserrat-SemiBold.ttf') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'),
        url('./Montserrat-SemiBoldItalic.ttf') format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url('./Montserrat-Bold.ttf') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url('./Montserrat-BoldItalic.ttf') format('woff');
    font-weight: 700;
    font-style: italic;
}
