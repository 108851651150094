@import '/src/assets/fonts/index.css';
@import-normalize;
@import '~swiper/css';
@import '~swiper/css/mousewheel';
@import '~swiper/css/scrollbar';
@import '~antd/dist/antd.css';

*,
*::after,
*::before {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
}
.ant-table-column-sorter {
    display: none;
}

input,
textarea {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.recharts-default-tooltip {
    background: #f4f6fa !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12) !important;
    border-radius: 4px !important;
    border: none !important;
    font-size: 14px !important;
    line-height: 17px !important;
    color: #444444 !important;
}

.recharts-default-tooltip .recharts-tooltip-item {
    word-wrap: break-word;
    white-space: pre-line !important;
    width: 150px !important;
    max-width: 150px !important;
    font-weight: 600;
}

.leaflet-pane {
    z-index: 9 !important;
}
.ant-upload-picture-card-wrapper {
    display: inline-block;
    width: 100% !important;
    height: 100% !important;
}
.ant-upload.ant-upload-select-picture-card {
    width: 100% !important;
    height: 100% !important;
}
.ant-upload.ant-upload-drag {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #EDF5FA !important;
    border: 1px dashed #4295CD !important;
    border-radius: 2px;
    cursor: pointer;
    transition: border-color 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 30px 0px 0px !important;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
    margin: 10px 0px !important;
}